<template>
  <p>กำลังเข้าสู่ระบบ...</p>
</template>

<script>
const config = require(`@/config`)
import authApiCpmService from "@/services/authCpmService"
import apiGatewayCpmService from "@/services/apiGatewayService"
import CryptoJS from 'crypto-js'

export default {
  name: "Callback",
  data() {
    return {
      adminList: ["nuttapong.pongkam@cmu.ac.th", "thapakorn.pe@cmu.ac.th"],
    }
  },
  created() {
    let _authCode = `${this.$route.query.code}`
    this.signinAuthenServices(_authCode)
  },
  methods: {
    async signinAuthenServices(code){
      let signed = await authApiCpmService.getCpmToken(config.auth.redirectUrl, code)
      if(signed){
        let _accessToken = `${signed['accessToken']}`
        let _refreshToken = `${signed['refreshToken']}`
        if(_accessToken){
          let profile = await apiGatewayCpmService.getProfile(_accessToken)
          if(profile){
            localStorage.setItem("accessToken", _accessToken)
            localStorage.setItem("refreshToken", _refreshToken)
            let profileStr = JSON.stringify(profile)
            localStorage.setItem("profile", CryptoJS.AES.encrypt(profileStr, config.aesKey.key).toString())
            const nextPath = `${localStorage.getItem("beforepath") ? localStorage.getItem("beforepath") : "/"}`
            // console.log("nextPath" ,nextPath)
            localStorage.removeItem("beforepath")
            this.$router.push({ path: nextPath })
          }
        }
      }
      // console.log("signin " ,signed)
    },
  }
}
</script>
